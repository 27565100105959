<template>
    <div class="table-wrap">
        <div class="first-column">
            <el-checkbox v-model="rowData.checked"  @change='change(rowData)' style="position:absolute;left:14px;top:40px"></el-checkbox>
            <div style="margin-right:20px">
                <!-- <el-image :src="rowData.imgUrl" alt="" ></el-image> -->
                <el-image :src="rowData.picUrl" alt="" fit="fit" style="width:102px;height:102px;"></el-image>
            </div>
            <div>
                <el-tooltip class="item" effect="dark" :content="rowData.productName" placement="top">
                    <p class="product-name omit">
                    {{rowData.productName}}
                    </p>
                </el-tooltip>
                    <!-- {{item.goodsName}} -->
                <div style="display:flex;margin-top:20px">
                    <!-- <div style="width:180px;flex:none;margin-right:20px">
                        <p class="omit" style="margin-bottom:5px;color:#999;">{{item.market}}</p>
                        <p>
                            <span style="color:#999">产品类型：</span>
                            <span style="color: #3F3F3F">{{rowData.categoryName}}</span>
                        </p>
                    </div> -->
                    <div>
                        <p style="margin-bottom:5px;">
                            <span style="color:#999">规格型号：</span>
                            <span style="color: #3F3F3F">{{rowData.productSpec}}</span>
                        </p>
                        <p>
                            <span style="color:#999">产品类型：</span>
                            <span style="color: #3F3F3F">{{rowData.categoryName}}</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="second-column">
            <h3 class="omit" style="text-align:center;">{{rowData.categoryName}}</h3>
        </div> -->
        <div class="second-column" style="120px;">
            <h3 class="omit" style="text-align:center;">{{rowData.reqNum}}{{rowData.unit}}</h3>
        </div>
        <div class="second-column" style="width:170px">
            <h3 class="omit" style="text-align:center;">{{rowData.maxPrice}}</h3>
        </div>
        <div class="second-column" style="width:170px">
            <h3 class="omit" style="text-align:center;">{{minPrice}}</h3>
        </div>
        <div class="second-column" style="width:170px">
            <!-- <h3 class="omit" style="text-align:center;">{{rowData.maxPrice}}</h3> -->
            <div class="num-box">
                <div class="min" :class="{disable: miunsBtnDisable}" @click="minusAmount(rowData)"><i class="el-icon-minus"></i></div>
                <div class="num" type="text">{{amount}}</div>
                <div class="add" :class="{disable: addBtnDisable}" @click="addAmount(rowData)"><i class="el-icon-plus"></i></div>
            </div>
        </div>
        <!-- <div class="second-column">
            <h3 class="omit" style="text-align:center;">{{rowData.weight*rowData.number+rowData.units}}</h3>
        </div>
        <div class="second-column">
            <h3 class="omit" style="text-align:center;font-weight:500">{{rowData.number}}</h3>
        </div>
        <div class="second-column">
            <h3 class="omit" style="text-align:center;font-weight:500;color:red">￥{{parseFloat(rowData.price *item.number).toFixed(2)}}</h3>
        </div> -->
        <div class="second-column" style="width: 100px;">
            <h3 style="">
                <el-button type="text" @click.stop="deleted(rowData.id)">删除</el-button>
            </h3>
        </div>
    </div>
</template>

<script>
    import { getCurrentMinPrice } from '../api';
    import Bus from '@/utils/bus';
    export default {
        props: {
            rowData: {
                type: Object
            }
        },
        data() {
            return {
                imgurl: require('@/assets/moren.jpg'),
                miunsBtnDisable: false,
                addBtnDisable: true,
                amount: 0,
                minPrice: 0
            }
        },
        created() {
            // this.rowData.amount = this.amount = this.rowData.maxPrice;
            this.getCurrentMinPrice();
        },
        mounted() {
            Bus.$on('updateMinPrice', this.getCurrentMinPrice);
        },
        destroyed() {
            Bus.$off('updateMinPrice', this.getCurrentMinPrice);
        },
        methods: {
            // 选中
            change(item) {
                this.$emit('select', item);
            },

            // 点击删除
            deleted(id) {
                this.$emit('delete', id);
            },
            minusAmount() {
                let amount = Number(this.rowData.amount);
                amount -= this.rowData.stepPrice || 10;
                if (amount <= this.rowData.maxPrice * 0.7) {
                    this.rowData.amount = Number((Math.round(this.rowData.maxPrice * 70) / 100).toFixed(2));
                    this.amount = Number((Math.round(this.rowData.maxPrice * 70) / 100).toFixed(2));
                    amount = Number((Math.round(this.rowData.maxPrice * 70) / 100).toFixed(2));
                    this.miunsBtnDisable = true;
                } else {
                    this.rowData.amount = Number((Math.round(amount * 100) / 100).toFixed(2));
                    this.amount = Number((Math.round(amount * 100) / 100).toFixed(2));
                    amount = Number((Math.round(amount * 100) / 100).toFixed(2));
                    this.miunsBtnDisable = false;
                }
                if (amount >= this.rowData.maxPrice) {
                    this.addBtnDisable = true;
                } else {
                    this.addBtnDisable = false;
                }
            },
            addAmount() {
                let amount = Number(this.rowData.amount);
                amount += this.rowData.stepPrice || 10;
                if (amount >= this.rowData.maxPrice) {
                    this.rowData.amount = Number((Math.round(this.rowData.maxPrice * 100) / 100).toFixed(2));
                    this.amount = Number((Math.round(this.rowData.maxPrice * 100) / 100).toFixed(2));
                    amount = Number((Math.round(this.rowData.maxPrice * 100) / 100).toFixed(2));
                    this.addBtnDisable = true;
                } else {
                    this.rowData.amount = Number((Math.round(amount * 100) / 100).toFixed(2));
                    this.amount = Number((Math.round(amount * 100) / 100).toFixed(2));
                    amount = Number((Math.round(amount * 100) / 100).toFixed(2));
                    this.addBtnDisable = false;
                }
                if (amount <= this.rowData.maxPrice * 0.7) {
                    this.miunsBtnDisable = true;
                } else {
                    this.miunsBtnDisable = false;
                }
            },
            getCurrentMinPrice() {
                getCurrentMinPrice({
                    productId: this.rowData.id
                }).then(res => {
                    if (res.errno === 0) {
                        this.rowData.minPrice = this.minPrice = res.data;
                        if (this.rowData.checked) {
                            this.rowData.amount = this.amount = this.minPrice = res.data;
                        }
                    }
                })
            }
        }
    }
</script>

<style lang="less" scoped>
    .table-wrap {
        display: flex;
        padding: 15px 0px;
        background: #fff;
        border-bottom: 1px solid #F4F4F4;
        align-items: center;

        .first-column {
            display: flex;
            width: 460px;
            padding-left: 40px;
            box-sizing: border-box;
            position: relative;
        }

        .second-column {
            width: 132px;
            text-align: center;
        }
        .num-box {
            display: flex;
            align-items: center;
            border: 1px solid #E4E4E4;

            .min, .add {
                width: 34px;
                height: 34px;
                text-align: center;
                line-height: 34px;
                cursor: pointer;
                background: #F6F7FB;

                &.disable {
                cursor: not-allowed;
                background: #eeeeee;
                }
            }
            .num {
                width: 140px;
                border: 1px solid #E4E4E4;
                border-top: 0;
                border-bottom: 0;
                text-align: center;
            }
        }
    }

    .product-name {
        font-weight: bold;
        font-size: 20px;
        margin: 10px 0;
        width:238px;
    }
</style>
