<template>
    <div style="width:1200px;margin:0 auto;padding:20px 0">
        <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/home/HomePage' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/home/LoogFor' }">竞买中心</el-breadcrumb-item>
            <el-breadcrumb-item>我的报价单</el-breadcrumb-item>
        </el-breadcrumb>

        <div>
            <h1>我的报价订单
                <span style="float:right;font-size:16px;font-weight:500;line-height:30px;color: #1F86E3;cursor:pointer;"
                    @click="$router.push('/home/LoogFor')">
                    继续选购资源>>
                </span>
            </h1>
            <div style="background:#E8E8E8;display:flex;text-align:center;padding:15px 0;">
                <div v-for="(item,index) in title" :key="index" :style="{'width':item.width,'font-size': '16px'}">
                    {{item.name}}</div>
            </div>
            <TableRowList v-for="item in companyList" :rowData='item' :key="item.id" @delete='deleteItem'></TableRowList>

            <div style="background:#fff;height:70px;margin-top:20px;position:relative">
                <div style="position: absolute;right:0;display:flex;">
                    <!-- <div style="margin-right:20px;text-align:right;padding-top:10px">
                        <p style="margin:5px 0">货款合计：<span style="color:#FF4805;font-weight:bold;font-size:16px ">{{countPrice}}</span>元</p>
                        <p >转货费：0.00元</p>
                    </div> -->
                    <div style="cursor:pointer;background: linear-gradient(to right,#F57838,#FF4603);line-height:70px;padding:0 30px;color:#fff;font-size:22px"
                        @click="buy">
                        立即报价
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import TableRowList from '../component/LoogForTableRowList'
    import { addProductQuote } from "../api";
    import Bus from '@/utils/bus';
    export default {
        components: {
            TableRowList
        },
        data() {
            return {
                title: [{
                    name: '商品信息',
                    width: '460px'
                }, 
                // {
                //     name: '钢厂',
                //     width: '132px'
                // },
                // {
                //     name: '计算方式',
                //     width: '110px'
                // }，
                // {
                //     name: '产品类型',
                //     width: '132px'
                // }, 
                {
                    name: '数量',
                    width: '120px'
                }, {
                    name: '最高竞买价(元)',
                    width: '170px'
                }, {
                    name: '当前最低价(元)',
                    width: '170px'
                }, {
                    name: '当前报价(元)',
                    width: '180px'
                }, {
                    name: '操作',
                    width: '100px'
                }],
                selectProductIds: JSON.parse(sessionStorage.getItem('loogForQuoteProductIds') || '[]'),
                selectProductList: JSON.parse(sessionStorage.getItem('loogForQuoteProductList') || '[]'),
                companyList: []
            }
        },
        computed: {
            countPrice() {
                let price = 0;
                this.selectProductList.forEach(item => {
                    item.checked && (price += item.amount);
                })
                return price;
            }
        },
        created() {
            this.fomatListData();
        },
        beforeDestroy() {
            localStorage.removeItem('certInfo');
        },
        methods: {
            fomatListData() {
                let companyIds = [];
                let companyList = {};
                this.selectProductList.forEach(item => {
                    if (!companyIds.includes(item.clientInfoId)) {
                        companyIds.push(item.clientInfoId)
                        companyList[item.clientInfoId] = {};
                        companyList[item.clientInfoId].list = [];
                        companyList[item.clientInfoId].clientName = item.clientName;
                        companyList[item.clientInfoId].userName = item.userName;
                        companyList[item.clientInfoId].phone = item.phone;
                        companyList[item.clientInfoId].clientAddress = item.clientAddress;
                    }
                });
                this.selectProductList.forEach(item => {
                    item.amount = item.maxPrice;
                    companyList[item.clientInfoId].list.push(item);
                });
                this.companyList = companyList;
            },
            buy() {
                let list = [];
                let ids = [];
                let isGreaterMinPrice = false;
                let errorItem = null;
                this.selectProductList.forEach(item => {
                    if (item.minPrice <= item.amount && item.checked) {
                        errorItem || (errorItem = item);
                        isGreaterMinPrice = true;
                    } 
                    if (item.checked) {
                        ids.push(item.id);
                        list.push({ 
                            productId: item.id,
                            proNum: item.reqNum,
                            amount: item.amount
                        })
                    }
                });
                if (!list.length) {
                    return this.$message.info("没有选中的报价商品");
                }
                if (errorItem && list.length === 1) {
                    return this.$message.warning(`${errorItem.productName} 的当前报价需小于最低报价${errorItem.minPrice}元，报价失败，请重新报价`); 
                }
                addProductQuote(list).then(res => {
                    if (res.errno === 0) {
                        const data = res.data || [];
                        data.forEach(id => {
                            const deleteIndex = this.selectProductIds.indexOf(id);
                            if (deleteIndex > -1) {
                                this.selectProductIds.splice(deleteIndex, 1);
                                this.selectProductList.splice(deleteIndex, 1);
                            }
                        });
                        if (!data.length) Bus.$emit('updateMinPrice');
                        if (!this.selectProductIds.length) {
                            setTimeout(() => {
                                this.$router.push({
                                    path: '/home/LoogFor'
                                });
                            }, 1000);   
                        }
                        this.fomatListData();
                        sessionStorage.setItem('loogForQuoteProductList', JSON.stringify(this.selectProductList));
                        sessionStorage.setItem('loogForQuoteProductIds', JSON.stringify(this.selectProductIds));
                    } else if (res.errno === -1) {
                        Bus.$emit('updateMinPrice');
                    }
                });
            },
            //删除
            deleteItem(id) {
                this.$confirm('确定删除该条产品吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    const deleteIndex = this.selectProductIds.indexOf(id);
                    this.selectProductIds.splice(deleteIndex, 1);
                    this.selectProductList.splice(deleteIndex, 1);
                    sessionStorage.setItem('loogForQuoteProductList', JSON.stringify(this.selectProductList));
                    sessionStorage.setItem('loogForQuoteProductIds', JSON.stringify(this.selectProductIds));
                    this.fomatListData();
                    if (!this.selectProductIds.length) {
                        setTimeout(() => {
                            this.$router.push({
                                path: '/home/LoogFor'
                            });
                        });   
                    }
                })
            }
        },
    }
</script>

<style>

</style>
