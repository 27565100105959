var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { width: "1200px", margin: "0 auto", padding: "20px 0" } },
    [
      _c(
        "el-breadcrumb",
        { attrs: { separator: "/" } },
        [
          _c(
            "el-breadcrumb-item",
            { attrs: { to: { path: "/home/HomePage" } } },
            [_vm._v("首页")]
          ),
          _c(
            "el-breadcrumb-item",
            { attrs: { to: { path: "/home/LoogFor" } } },
            [_vm._v("竞买中心")]
          ),
          _c("el-breadcrumb-item", [_vm._v("我的报价单")]),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("h1", [
            _vm._v("我的报价订单 "),
            _c(
              "span",
              {
                staticStyle: {
                  float: "right",
                  "font-size": "16px",
                  "font-weight": "500",
                  "line-height": "30px",
                  color: "#1F86E3",
                  cursor: "pointer",
                },
                on: {
                  click: function ($event) {
                    return _vm.$router.push("/home/LoogFor")
                  },
                },
              },
              [_vm._v(" 继续选购资源>> ")]
            ),
          ]),
          _c(
            "div",
            {
              staticStyle: {
                background: "#E8E8E8",
                display: "flex",
                "text-align": "center",
                padding: "15px 0",
              },
            },
            _vm._l(_vm.title, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  style: { width: item.width, "font-size": "16px" },
                },
                [_vm._v(" " + _vm._s(item.name))]
              )
            }),
            0
          ),
          _vm._l(_vm.companyList, function (item) {
            return _c("TableRowList", {
              key: item.id,
              attrs: { rowData: item },
              on: { delete: _vm.deleteItem },
            })
          }),
          _c(
            "div",
            {
              staticStyle: {
                background: "#fff",
                height: "70px",
                "margin-top": "20px",
                position: "relative",
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    position: "absolute",
                    right: "0",
                    display: "flex",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        cursor: "pointer",
                        background: "linear-gradient(to right,#F57838,#FF4603)",
                        "line-height": "70px",
                        padding: "0 30px",
                        color: "#fff",
                        "font-size": "22px",
                      },
                      on: { click: _vm.buy },
                    },
                    [_vm._v(" 立即报价 ")]
                  ),
                ]
              ),
            ]
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }