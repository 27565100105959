var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticStyle: { margin: "30px 0 10px 0", "padding-left": "40px" } },
        [
          _c("span", { staticStyle: { "margin-right": "40px" } }, [
            _vm._v("竞买商："),
            _c("span", { staticStyle: { color: "#538FF4" } }, [
              _vm._v(
                _vm._s(_vm._f("hideTextInfo")(_vm.rowData.clientName, 3, 4))
              ),
            ]),
          ]),
          _c("span", { staticStyle: { "margin-right": "40px" } }, [
            _vm._v(
              "联系方式：" +
                _vm._s(_vm._f("hideTextInfo")(_vm.rowData.userName, 1, 0)) +
                " " +
                _vm._s(_vm._f("hideTextInfo")(_vm.rowData.phone, 3, 4))
            ),
          ]),
          _c("span", [
            _vm._v(
              "所在地：" +
                _vm._s(_vm._f("hideTextInfo")(_vm.rowData.clientAddress, 3, 4))
            ),
          ]),
        ]
      ),
      _vm._l(_vm.rowData.list, function (item) {
        return _c("TableRowItem", {
          key: item.id,
          attrs: { rowData: item },
          on: { delete: _vm.deleted },
        })
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }