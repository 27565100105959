<template>
    <div>
        <div style="margin:30px 0 10px 0;padding-left:40px">
            <span style="margin-right:40px">竞买商：<span style="color: #538FF4">{{rowData.clientName | hideTextInfo(3, 4)}}</span></span>
            <span style="margin-right:40px">联系方式：{{rowData.userName | hideTextInfo(1, 0)}}  {{rowData.phone | hideTextInfo(3, 4)}}</span>
            <span>所在地：{{rowData.clientAddress | hideTextInfo(3, 4)}}</span>
        </div>

        <TableRowItem v-for="item in rowData.list" :rowData='item' :key="item.id" @delete='deleted'></TableRowItem>
    </div>
</template>

<script>
    import { hideTextInfo } from '@/utils/util';
    import TableRowItem from './LoogForTableRowItem';
    export default {
        components: {
            TableRowItem,
        },
        props: {
            rowData: {
                type: Object
            }
        },
        data() {
            return {
                
            }
        },
        filters: {
            hideTextInfo
        },
        created() {
        },
        methods: {
            // 选中
            change(item) {
                this.$emit('select', item);
            },

            // 点击删除
            deleted(id) {
                this.$emit('delete', id);
            },
        }
    }
</script>
